<template>
<div class="t-scroll" ref="scroll">
  <div class="t-scroll-fixed">
    <slot name="fixed"></slot>
  </div>
  <div class="t-scroll-box">
    <slot></slot>
  </div>
</div>
</template>

<script>
// 定时器id
let timer = null;

// 无缝滚动组件
export default {
  name: "Scroll",
  props:{
    // 内容宽度
    width:Number|String,
    // 鼠标移到上面停止滚动
    hover:Boolean,
    // 是否启用滚动
    scroll:{
      type:Boolean,
      default:()=>false
    }

  },
  watch:{
    scroll(n,o){
      if (n)setTimeout(()=>{this.interval();})
    }
  },
  data(){
    return {
      left:0
    }
  },
  mounted() {
    this.interval();
    this.onHover();
  },
  methods:{
    onHover(){
      let $this = this;
      if (this.hover)
        this.$el.onmouseover = function (e) {
          clearInterval(timer);
          this.onmouseout = function(e){
            $this.interval();
          }
        }
    },
    interval(){
      if (!this.scroll)return;
      let el = this.$slots.default[0].elm;
      let parent = el.parentNode;
      let root = this.$el;
      let rw = root.clientWidth;
      timer = setInterval(()=>{
        let pw = parent.clientWidth;
        let cw = el.clientWidth;
        if(pw !== cw * 2) {
          parent.style.width = cw*2+"rem";
          parent.style.display = "flex";
        }
        // parent.style.transition = "5s linear"
        // parent.style.transform = `translateX(${-cw}rem)`;
        let left = this.left;
        if (left >= cw){
          this.left = 0;
        }
        parent.style.left = -left + "rem";
        this.left += 0.5;
      },20)
    }
  }
}
</script>

<style lang="scss" scoped>
  .t-scroll{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .t-scroll-box{
      height: 100%;
      position: absolute;
      min-width: 100%;
      left: 0;
      top: 0;
      .t-scroll-slot{
        height: 100%;
      }
    }
  }
</style>
