<template>
  <div id="echarts" ref="echarts">
  </div>
</template>

<script>
import * as echarts from "echarts";
import Echarts,{sh,sw} from "./Echarts";

export default {
  name: "SevenDayOneToOne",
  mounted() {
    this.$_axios2('/api/visual-kanban/index/index',{params: {type: '4', school_id: this.$route.query.school_id}}).then(res => {
      if (res.data.status !== 0){
        return this.$parent.noData = 1;
      }else{
        let data = res.data.data.coach
        let max = res.data.data.coachMax
        this.lineInit(data)
      }
    }).catch(err=>{
      const msg = err.message.toLowerCase();
      if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
        this.$parent.noData = 2
      else this.$parent.noData = 1;
    })
  },
  methods: {
    lineInit(lineData) {
      // return;
      //mock-data
      // lineData = {
      //   data: [
      //     {
      //       "school_name": "达州鸿鹄高级中学",
      //       "list": [
      //         {"number": 2, "day": "09.07"},
      //         {"number": 5, "day": "09.08"},
      //         {"number": 14, "day": "09.09"},
      //         {"number": 2, "day": "09.10"},
      //         {"number": 17, "day": "09.11"},
      //         {"number": 8, "day": "09.12"},
      //         {"number": 11, "day": "09.13"}
      //       ]
      //     },
      //     {
      //       "school_name": "资阳鸿鹄高级中学",
      //       "list": [
      //         {"number": 5, "day": "09.07"},
      //         {"number": 20, "day": "09.08"},
      //         {"number": 1, "day": "09.09"},
      //         {"number": 15, "day": "09.10"},
      //         {"number": 13, "day": "09.11"},
      //         {"number": 4, "day": "09.12"},
      //         {"number": 9, "day": "09.13"}
      //       ]
      //     }
      //   ],
      //   "coachMax": 20
      // };

      let chartDom = this.$refs['echarts'];
      let myChart = echarts.init(chartDom)
      let series = [];
      let colorList = [
        {
          color: '#1890FF',
          icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAQxJREFUKFOdkr1LA0EQxX9vjZ4foBZqc51YJCiYKpVY2qiVf0Ew4J8kwkl6wUptrK2sUgRMIXZp1EIFP07DjtxxJ+el0Wy1++bNvHmzI4rHTCttFgJY/BowM17hNYaH2yaPSJZTlV+Wj2wuCNhwnvBXIcA7+nHM1d2BnpNYmlRv2/ynsSOYLifkb4O3CXHeaepJnNhY7YVd51hKCAaTgqoZoUTfoCf4SGLec38zy5nWDy0cBGynsp4pDy3EWkGx64zIHO8JVom50GpkDRz1lGQ0DPbLLQqOEdcp7umoFtmmc1QzYM/E1lCScYnjNGuxN5rSkCfRgoIno+soeRppeqP9U+b63xvxM60/7t439W2LIIO9guwAAAAASUVORK5CYII='
        },
        {
          color: '#41E4BB',
          icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAP1JREFUKFOdkrtOw0AQRc/1SphHAYoAOTKRCFUKCqpUiJIGqPgE+Cz4BCqgoaaioqBIRZCsJBagCAoeRnIGGW2QiSjAW83u3DOz8xDlY6YoTRenYMlgTvDyAQ9pFD0i2ViqsbE2HM7nWbZpZvGPQICkngvDy9ta7bnwfUGr3e7CKAx3gdlJoHR/DbLs7K7ZfBJmrjEY7AmWvWDaoCUpNrOeoAO8Fz6D+6ReP9VKksSBczsemEE6BNZLGW4wOwLeirdRnp8XUDtwbsNHaks6mPyimR0Lrjx0rUa/vyVoeWhf0vYv0IXgxGs61TJVqqlS96rNyVf974347tYfd+8Ti2SWYbhV2GAAAAAASUVORK5CYII='
        },
      ];


      let series_init = {
        name: '',
        data: [],
        type: 'line',
        smooth: false,
        cursor: 'pointer',
        symbol: '',
        showSymbol: true,
        symbolSize: 16,
        itemStyle: {
          color: '#41E4BB',
        },
        lineStyle: {
          cap: 'round',
          width: 2
        },
        label: {
          show: true,
          position: 'top',
          fontSize: sw(10),
          color: '#fff',
          distance: sh(1)
        },
        emphasis: {
          disabled: true
        }
      }

      if (lineData.length) {
        let data = lineData.map(item => {
          return item.list.map(c_item => {
            return [c_item.day, c_item.number]
          })
        })
        // console.log(data)
        data.forEach((item, index) => {
          let obj = JSON.parse(JSON.stringify(series_init))
          obj.name = lineData[index].school_name;
          obj.data = item
          obj.itemStyle.color = colorList[index].color
          obj.symbol = colorList[index].icon
          series.push(obj)
        })
        // console.log(data)
      }


      let option = {
        grid: {
          bottom: 30,
          left: sw(37),
          right: sw(42),
          top: sh(45),
        },
        legend: {
          icon: 'roundRect',
          selectedMode: false,
          top:sh(2),
          itemWidth:sw(10),
          itemHeight:sh(10),
          itemGap: 24, // 设置间距
          textStyle:{
            fontSize:sw(10),
            color: '#bbb',
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: ['30%', '30%'],
          axisLabel: {},
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        series
      }

      option && myChart.setOption(option, {
        notMerge: true
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#echarts {
  height: 100%;
  background-image: url("../../../assets/img/data-visualization-line-bg.png");
  background-size: 88%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
