import axios from 'axios'
import tools from './tools'
import axiosConfig from './axios.config'
import router from '../router'
import vuex from '../store/index'

import elem from 'element-ui'
let axiosObj = axios.create({
  errorToCatch: true, // 是否将请求接口中不为0的错误通过fetch传递
  showMessage: true, // 请求接口失败时, 返回status不为0时 是否显示提示
  logic: 0, // 拦截逻辑, 默认0 , 使用原本逻辑 , 1 使用新逻辑
  baseURL: process.env.VUE_APP_URL_THREE,
  timeout: 10000
})
axiosObj.interceptors.request.use(config => {
  let token = tools.getSession(tools.constants.token)
  let traceid = tools.getSession('traceId')
  if (token) {
    config.headers.authorization = 'Bearer ' + token
    if (!!traceid) config.headers.traceid = traceid
  }
  if (config.headers.timeout) {
    config.timeout = config.headers.timeout
  }
  return config
})
axiosObj.interceptors.response.use(
    response => {
      const { errorToCatch, showMessage, logic } = response.config
      if (logic === 0) {
        if (response.data.status === 1) {
          elem.Message.error({ message: response.data.msg, showClose: true })
          //此处修改是因为测试人员说错误不能在控制台打印出来,若有疑问，请联系测试人员
          // return Promise.reject(response);
          return Promise.resolve(response)
        }
        if (response.data.status === 0) {
          return Promise.resolve(response)
        }
        if (response.data.status === 104) {
          return Promise.resolve(response)
        }
        if (response.data.status === 100) {
          router.push('/login')
        }
        if (response.data.status !== 0) {
          if (response.data.status) {
            elem.Message.error({ message: response.data.msg, showClose: true })
          }
          return Promise.resolve(response)
        }
      } else if (logic === 1) {
        let { status } = response.data

        if ([0, 104].includes(status)) return Promise.resolve(response.data)
        else if (status === 100) router.push('/login')
        // 错误
        if (response.data.status !== 0) {
          if (showMessage) elem.Message.error({ message: response.data.msg, showClose: true })
          if (errorToCatch) return Promise.reject(response.data)
          else return Promise.resolve(response.data)
        }
      }
    },
    err => {
      tools.networkHandle(err, vuex.state.showNetError)
      return Promise.reject(err)
    }
)
//暴露配置好的axios
export default axiosObj
