import {utils} from "../utils/utils";


/**
 * @description DFS 遍历后台返回的数据节点
 * @param node
 * @return {[]}
 */
function DFSServerConfig(node) {
    let names = [];
    if (node != null) {
        let stack = [];
        for (let i = node.length-1;i>=0;i--)stack.push(node[i]);
        while (stack.length !== 0) {
            let item = stack.pop();
            let title = item.name;
            names.push(title);
            let child = item.child||[];
            for (let i = child.length - 1; i >= 0; i--)
                stack.push(child[i]);
        }
    }
    return names;
}


export default {
    analyseRouter(context){
        return utils.loginApi.getMenu().then(async res=>{
            let {status,errMsg,data} = res;
            if (status === 0){
                context.commit("setMenuConfig",data);
                context.commit("setRightMenuIndex",-1);
                return {status, data};
            }else{
              throw errMsg;
            }
        })
    },
    getButtonPermissions(context,id){
        return utils.loginApi.getButtonPermissions(id).then(res=>{
            const transformData = (arr) => {
                return arr.reduce((acc, item) => {
                    const modifiedType = 'on' + item.type.charAt(0).toUpperCase() + item.type.slice(1);
                    acc[modifiedType] = item.button;
                    return acc;
                }, {});
            };
            let str = window.location.pathname
            let {status,errMsg,data} = res;
            context.commit("setButtonPermission",data);
            return {status, data};
        }).catch(err=> context.commit("setButtonPermission",[]))
    }
}
