<template>
	<div class="t_dialog">
		<t-dialog ref="dialog" v-bind="$attrs" v-on="$listeners">
			<template slot="title">
				<div class="t_title_top">{{ title }}</div>
				<div class="t_title_bottom" v-if="title != null && title != ''"></div>
			</template>
			<div class="t_title_body">
				<slot> </slot>
			</div>
			<div class="t_title_footer" slot="footer">
				<slot name="footer"></slot>
			</div>
		</t-dialog>
	</div>
</template>

<script>
import { Dialog } from 'element-ui'

export default {
	props: {
		title: String
	},
  watch:{
    "$attrs.visible"(n,o){
      if (n){
        if (parseInt(this.$refs.dialog.$el.style.zIndex) < 1000){
          this.$refs.dialog.$el.style.zIndex = 2000;
        }
      }
    }
  },
	components: {
		't-dialog': Dialog
	},
	methods: {
		close() {
			this.$emit('update:visible', false)
		}
	}
}
</script>

<style lang="scss">
.t_dialog {
	.t_title_top {
		font-size: 16rem;
		font-weight: 500;
		border-left: 4rem solid #1d2088;
		padding-left: 10rem;
		margin-left: 24rem;
		margin-top: 14rem;
	}
	.t_title_bottom {
		margin-top: 10rem;
		margin-left: 24rem;
		border-bottom: 1rem solid #eaeaea;
	}
	.t_title_body {
		padding: 0rem 24rem !important;
	}
  .t_title_footer{
    margin-top: 24rem !important;
  }
}
</style>
