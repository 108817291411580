<template>
  <div style='height: calc(100%);width: 100%;'>
    <div class='title_name'>
      <span :style="{fontSize:fontSize(12)+'rem',paddingLeft:fontSize(20)+'rem',paddingRight:fontSize(20)+'rem'}"
            v-for='item in school_name'>{{ item }}</span>
    </div>
    <div id='DormitoryOccupancy' style='height: calc(100%);width: 100%;'></div>
    <div style='height: calc(100%);width: 100%;' v-if='false'></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { sh, sw } from "@/views/DataVisualization/components/Echarts";

export default {
  name: 'DormitoryOccupancy',
  data() {
    return {
      school_name: []
    };
  },

  methods: {
    // 获取屏幕宽度并计算比例
    fontSize(px) {
      let clientWidth = window.innerHeight || document.body.clientHeight; //屏幕尺寸
      if (!clientWidth) {
        return 0;
      }
      let fontSize = clientWidth / 1080; //设计稿尺寸
      return px * fontSize;  //转换为 rem的布局返回出去
    },
  },

  mounted() {
    this.$_axios2.get('api/visual-kanban/index/index?type=1', { params: { school_id: this.$route.query.school_id } }).then(res => {
      if (res.data.status === 0) {
        this.school_name = res.data.data.dormitory.school;
        if (res.data.data.dormitory.num === 2) {
          let chartDom = document.getElementById('DormitoryOccupancy');
          let myChart = echarts.init(chartDom);
          let daZhouBoy = [];
          let ziYangBoy = [];
          let daZhouGirl = [];
          let ziYangGirl = [];
          res.data.data.dormitory.boy[0].forEach((i, n) => {
            daZhouBoy.push({
              xAxis: (0 - i) + (0 - res.data.data.dormitory.girl[0][n]),
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: Math.abs(i) + '', color: 'rgba(255,255,255,0.87)', fontSize: this.fontSize(10) }
            });
          });
          res.data.data.dormitory.boy[1].forEach((i, n) => {
            ziYangBoy.push({
              xAxis: i + res.data.data.dormitory.girl[1][n],
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: Math.abs(i) + '', color: '#E6F7FF', fontSize: this.fontSize(10) },
            });
          });
          res.data.data.dormitory.girl[0].forEach((i) => {
            daZhouGirl.push({
              xAxis: (0 - i),
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: Math.abs(i) + '', color: '#E6F7FF', fontSize: this.fontSize(10) },
            });
          });
          res.data.data.dormitory.girl[1].forEach((i) => {
            ziYangGirl.push({
              xAxis: i,
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: Math.abs(i) + '', color: '#E6F7FF', fontSize: this.fontSize(10) },
            });
          });
          let option = {
            tooltip: {
              formatter: () => ''
            },
            legend: {
              itemWidth: sw(10),
              itemHeight: sw(10),
              top: this.fontSize(-2),
              x: 'left',
              selectedMode: false, // 是否允许点击
              textStyle: {
                color: 'rgba(255,255,255,0.87)',//字体颜色
                fontSize: sh(10)
              },
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: this.fontSize(0),
              top: this.fontSize(24),
              containLabel: true
            },
            title: {
              textStyle: {
                fontSize: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
              },
            },
            xAxis: [
              {
                show: false,
                type: 'value',
                axisLabel: {
                  formatter: value => {
                    if (value < 0) {
                      return -value;
                    }//这里是针对取负值
                    else {
                      return value;
                    }
                  },
                },
                max: Math.max(...res.data.data.dormitory.max) + 50,
                min: -Math.max(...res.data.data.dormitory.max) - 50,
                markLine: {
                  symbol: ['none', 'none'], //去掉箭头
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: 'dashed', //线条样式
                        color: {
                          //设置渐变
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: 'red ', // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: 'red', // 100% 处的颜色
                            },
                          ],
                          global: false, // 缺省为 false
                        },
                      },
                      label: {
                        show: true,
                        position: 'start', // 文字显示的位置
                      },
                    },
                  },
                  data: [
                    {
                      name: 'min line on close',
                      type: 'min',
                      valueDim: 'close',
                      yAxis: 3.7, // 标记线x轴的值
                    },
                    {
                      name: 'max line on close',
                      type: 'max',
                      valueDim: 'close',
                      yAxis: 9, // 第二条标记线x轴的值
                    },
                  ],
                },
              }
            ],
            yAxis: [
              {
                axisLine: {
                  show: true,
                  onZero: true,
                  lineStyle: {
                    //利用阴影进行延长
                    shadowOffsetX: 0,
                    shadowOffsetY: this.fontSize(-30),
                    shadowColor: '#fff'
                  },
                },
                type: 'category',
                axisPointer: { type: 'none' },
                axisLabel: {
                  textStyle: {
                    color: 'rgba(255,255,255,0.87)',
                    fontSize: this.fontSize(12)
                  },
                },
                axisTick: { show: false },
                splitLine: { // 网格线
                  show: true,
                  interval: 100,
                  lineStyle: {
                    color: '#fff',
                    width: 1,
                  },
                },
                data: res.data.data.dormitory.grade,
              }
            ],
            series: [
              {
                name: '女',
                type: 'bar',
                stack: 'one',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside',
                    formatter: function (params) {
                      return Math.abs(params.value);
                    }  //返回绝对值
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.girl[0].map(item => 0 - item),
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: '#9B51E0'
                  }, {
                    offset: 1,
                    color: '#633571'
                  }]),
                },
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}'
                  },
                  data: daZhouGirl,
                }
              },
              {
                name: '女',
                type: 'bar',
                stack: 'one',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside'
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.girl[1],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: '#9B51E0'
                  }, {
                    offset: 1,
                    color: '#633571'
                  }]),
                },
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}',
                  },
                  data: ziYangGirl,
                }
              },
              {
                name: '男',
                type: 'bar',
                stack: 'one',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside',
                    formatter: function (params) {
                      return Math.abs(params.value);
                    }  //返回绝对值
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.boy[1],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: '#1890FF'
                  }, {
                    offset: 1,
                    color: '#1a65ae'
                  }]),
                },
                showBackground: true, //柱状背景
                backgroundStyle: { //背景颜色，加透明度
                  color: '#1a2636'
                },
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}'
                  },
                  data: ziYangBoy,
                }
              },
              {
                name: '男',
                type: 'bar',
                stack: 'one',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside'
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.boy[0].map(item => 0 - item),
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: '#1890FF'
                  }, {
                    offset: 1,
                    color: '#1a65ae'
                  }]),
                },
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}'
                  },
                  data: daZhouBoy,
                }
              },
            ]
          };
          option && myChart.setOption(option);
        } else {
          let chartDom = document.getElementById('DormitoryOccupancy');
          let myChart = echarts.init(chartDom);
          let daZhouBoy = [];
          let daZhouGirl = [];
          res.data.data.dormitory.boy[0].forEach((i, n) => {
            daZhouBoy.push({
              xAxis: i + res.data.data.dormitory.girl[0][n],
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: i + '', color: '#E6F7FF', fontSize: this.fontSize(10) }
            });
          });
          res.data.data.dormitory.girl[0].forEach((i) => {
            daZhouGirl.push({
              xAxis: i,
              lineStyle: { color: '#BAE7FF' },
              label: { formatter: i + '', color: '#E6F7FF', fontSize: this.fontSize(10) },
            });
          });
          let option = {
            legend: {
              itemWidth: sw(10),
              itemHeight: sw(10),
              top: this.fontSize(0),
              x: 'right',
              selectedMode: false, // 是否允许点击
              textStyle: {
                color: '#ccc',//字体颜色
                fontSize: sh(10)
              },
              padding: [0, sh(24), 0, 0]
            },
            grid: {
              left: '3%',
              right: '4%',
              containLabel: true,
              top: this.fontSize(36),
              bottom: this.fontSize(16)
            },
            xAxis: {
              type: 'value',
              max: Math.max(...res.data.data.dormitory.max) + 50,
              show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              axisLine: {
                show: false, // 不显示坐标轴线
              },
              axisLabel: {
                show: false, // 不显示坐标轴上的文字
              },
              splitLine: {
                show: false // 不显示网格线
              },
            },
            yAxis: {
              type: 'category',
              data: res.data.data.dormitory.grade,
              axisTick: {       //y轴刻度线
                "show": false
              },
              axisLabel: {
                textStyle: {
                  color: 'rgba(255,255,255,0.87)',
                  fontSize: this.fontSize(12)
                },
              },
              splitLine: { // 网格线
                show: true,
                interval: 100,
                lineStyle: {
                  color: 'rgba(255,255,255,0.87)',
                  width: 1,
                },
              },
            },
            series: [
              {
                name: '女',
                type: 'bar',
                stack: 'total',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside'
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.girl[0],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: '#9B51E0'
                  }, {
                    offset: 1,
                    color: '#633571'
                  }]),
                  showBackground: true, //柱状背景
                },
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}'
                  },
                  data: daZhouGirl,
                }
              },
              {
                name: '男',
                type: 'bar',
                stack: 'total',
                label: {
                  normal: {//正常情况
                    show: false, //是否显示标签
                    position: 'inside'
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: res.data.data.dormitory.boy[0],
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: '#1890FF'
                  }, {
                    offset: 1,
                    color: '#1a65ae'
                  }]),
                },
                showBackground: true, //柱状背景
                markLine: {
                  symbol: ['none', 'none'], // 去掉箭头
                  label: {
                    show: true,
                    position: 'start',
                    formatter: '{b}'
                  },
                  data: daZhouBoy,
                }
              },
            ]
          };
          option && myChart.setOption(option);
        }
      } else {
        this.$parent.noData = 1;
      }
    }).catch(err => {
      const msg = err.message.toLowerCase();
      if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
        this.$parent.noData = 2
      else this.$parent.noData = 1;
    });
  }
};
</script>

<style scoped lang='scss'>
.title_name {
  width: sw(480);
  font-size: 14rem;
  position: absolute;
  text-align: center;
  padding-left: 36rem;
  z-index: 999999999999999999;
  height: sh(170);
}
</style>
