<template>
  <div class="t-result">
    <div>
      <img :src="iImage" :alt="text" />
      <p class="t-text">{{ iText }}</p>
    </div>
<!--    <div v-if="type==='404'">
      <img src="../assets/img/404.png" :alt="text" />
      <p class="t-text">{{ iText }}</p>
    </div>-->
    <slot name="footer"></slot>
  </div>
</template>

<script>
import nodata from "../assets/img/nodata.png"
import nocontent from "../assets/img/nocontent.png"
import $404 from "../assets/img/404.png"
export default {
  name: "t-result",
  props:{
    // 包含 [nodata,nocontent,empty,404]
    type:{
      type: String,
      default(){
        return "empty"
      }
    },
    text:String,

  },
  computed:{
    iType(){
      let type = this.type;
      if (type === 'empty')type = 'nodata';
      return type;
    },
    iText(){
      let text = this.text;
      let type = this.iType;
      if (text)return text;
      switch (type){
        case "nodata":
          return '暂无数据';
        case "nocontent":
          return "暂无内容";
        case "404":
          return " 唔...页面跑丢了 o(╥﹏╥)o";
        default:
          return "";
      }
    },
    iImage(){
      switch (this.iType){
        case "nodata":
          return nodata;
        case "nocontent":
          return nocontent;
        case "404":
          return $404;
        default:
          return "";
      }
    }
  }
}
</script>

<style scoped lang="scss">
.t-result{
  padding: 100rem 0;
  width: 100%;
  text-align: center;
  >div{
    .t-text{
      font-size: 14rem;
      margin-top: 20rem;
      color: #666666;
    }
  }
}
</style>
