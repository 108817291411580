<template>
  <div class="main">
    <div class="title" :style="{ background: titleBg }">
      <div class="title_left">
        <div class="title_left_title">{{ title }}</div>
        <div class="title_left_num" :style="{color:totalColor}">{{total}}</div>
      </div>
      <div class="title_right" v-if="total > 10">
        <div style="cursor: pointer;" @click="onUpPage"><i class="el-icon-caret-left"></i></div>
        <div style="cursor: pointer;" @click="onDownPage"><i class="el-icon-caret-right"></i></div>
      </div>
    </div>
    <div class="table" v-if="tableData && tableData.length">
      <el-table :data="tableData" style="width: 100%;background-color: transparent" size="small">
        <el-table-column show-overflow-tooltip v-for="item in config" :prop="item.prop" :label="item.name" :width="item.width">
          <template slot-scope="scope">
            <div class="text-one">
              <span v-if="item.render" v-html="item.render(scope.row)"></span>
              <span v-else v-html="scope.row[item.prop]"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <t-result v-else type='empty'></t-result>
  </div>
</template>

<script>
export default {
  name: "l-table",
  data(){
    return{ }
  },
  // 背景默认为绿色
  props:{
    titleBg:String, // 标题背景
    totalColor:String, // 标题颜色
    title:String, // 标题文字
    total:String && Number, // 总数
    tableData:Array, // 表格数据
    config:Array, // 表格配置
  },
  methods:{
    onUpPage() {
      this.$emit('upPage')
    },
    onDownPage() {
      this.$emit('downPage')
    }
  }
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 200rem;
  background: #fdfdff;
  border-radius: 20rem;
  ::v-deep .el-table tr{
    background-color: transparent;
  }
  ::v-deep .el-table th.el-table__cell{
    background-color: transparent;
  }
  .title{
    width: 100%;
    height: 48rem;
    background: #f1fff4;
    border-radius: 20rem 20rem 0 0;
    display: flex;
    justify-content: space-between;
    line-height: 48rem;
    padding: 0 32rem;
    .title_left{
      display: flex;
      .title_left_title{
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
      }
      .title_left_num{
        font-size: 20rem;
        font-weight: bold;
        color: #23c343;
        margin-left: 10rem;
      }
    }
    .title_right{
      display: flex;
      font-size: 24rem;
    }
  }
  .table{
    padding: 0 24rem 24rem;
    ::v-deep .el-table__cell{
      width: 60rem;
      height: 18rem;
      font-weight: 400;
    }
  }
  ::v-deep .el-table__cell{
    border:none !important;
  }
  ::v-deep .el-table::before{
    height: 0;
  }
  ::v-deep.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
    background: #fff;
  }
}
</style>
