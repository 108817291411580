import axiosSource from "axios"
import tools from "./tools";
import router from "../router"
import elem from "element-ui";

const axios = axiosSource.create({
  baseURL: process.env["VUE_APP_URL_Register"],
  timeout: 10000,
  showError: true,
  logic: 0 // 添加是否需要错误提示
});
axios.interceptors.request.use(config => {
  let token = tools.getSession(tools.constants.token);
  let traceid = tools.getSession("traceId");
  if (token) {
    config.headers.authorization = "Bearer " + token;
    if (!!traceid) config.headers.traceid = traceid;
  }
  return config;
});
axios.interceptors.response.use(
    response => {
      const {logic} = response.config
      if(response.headers['content-type'] === 'application/octet-stream'){
        return Promise.resolve(response);
      }
      if (response.data.status === 1) {
        if (response.config.showError && logic === 0)
          elem.Message.error({message: response.data.msg, showClose: true, customClass: 'messageIndex'});
        //此处修改是因为测试人员说错误不能在控制台打印出来,若有疑问，请联系测试人员
        return Promise.reject(response);
        // return Promise.resolve(response);
      }
      if (response.data.status === 0) {
        return Promise.resolve(response);
      }
      if (response.data.status === 104) {
        return Promise.resolve(response);
      }
      if (response.data.status === 100) {
        router.push("/login");
      }
      if (response.data.status !== 0) {
        if (response.data.type === "application/octet-stream") {
          return Promise.resolve(response);
        } else {
          if (response.config.showError && logic === 0)
            elem.Message.error({message: response.data.msg, showClose: true, customClass: 'messageIndex'});
          return Promise.reject(response);
        }
      }
    }, (err) => {
      tools.networkHandle(err);
      return Promise.reject(err);
    }
);
export default axios;
