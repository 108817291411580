<template>
  <div class="left-menu">
    <!-- logo -->
    <div class="logo" @click="goHome">
      <img src="../../assets/img/left-logo.png" alt="" style="width: 100%;margin-left:-20rem;padding:0 20rem;">
    </div>
    <t-menu v-show="$route.name !== 'home'" :current-active="defaultActive" unique-opened router class="menu" v-if="menuConfig.length>0 && rightMenuIndex>=0">
      <t-submenu v-for="(item,i) in menuConfig[rightMenuIndex].child" :index="item.web_route" :key="item.web_route">
        <template slot="title">
					<span class="sub_menu">
            {{ item.name }}
					</span>
        </template>
        <t-menu-item-group>
          <t-menu-item v-for="(sub,index) in item.child" :index="sub.web_route" line :key="'submenu-'+sub.web_route">{{ sub.name }}</t-menu-item>
        </t-menu-item-group>
      </t-submenu>
    </t-menu>
  </div>
</template>

<script>
import config from "../../router/config.js"
import {mapGetters, mapState} from "vuex"

export default {
  computed: {
    ...mapState(["rightMenuIndex"]),
    ...mapGetters(["menuConfig"]),
    // defaultActive(){
    //   return this.$info.matched[this.$info.matched.length-2].path;
    // }
  },
  watch: {
    rightMenuIndex(n, o) {
      this.$forceUpdate()
    },
    "$route"(n, o) {
      let len = n.matched.length;
      this.defaultActive = n.matched[len - 2].path;
    },
  },
  data() {
    return {
      menus: config,
      defaultActive: this.$route.matched[this.$route.matched.length - 2].path
    }
  },
  beforeRouteLeave(to, form, next) {
  },
  mounted() {
  },
  methods: {
    log:console.log,
    goHome() {
      if (this.menuConfig.filter(item=>item.name==="首页").length) {
        this.$router.push('/main')
      } else
        this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_setting.scss";
.logo {
  display: flex;
  cursor: pointer;
  height: 64rem;
  padding-left: 20rem;
  align-items: center;
  font-weight: 500;

  img {
    width: 50rem;
    margin-right: 18rem;
  }

  .title {
    color: $primary;
    font-weight: 700;
  }
}

.sub_menu {
  font-size: 16rem;
  font-weight: 500;
  line-height: 24rem;
  color: $menu;
  height: 100%;
  width: 100%;
}
::v-deep .t-submenu-show {
  .sub_menu{
    color: $primary !important;
  }
}
.menu {
  border-right: 1rem solid #eee;
  height: calc(100vh - 64rem);
  overflow-y: auto;
}

.menuitem {
  font-size: 16rem;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  line-height: 24rem;
  color: #383fa0;
}

.el-menu-item.menuitem.is-active {
  background-color: $menuItem-active-bg;
  border-right: 2rem solid $primary;
}
</style>
