<template>
  <div class="r-echarts-card">
    <div class="t-echarts-top">
      <i class="icon el-icon-caret-right"></i>
      <span class="t-title">{{title}}</span>
    </div>
    <div v-if="noData === 0" class="t-echarts-content" :style="{height:getHeight}">
      <slot></slot>
    </div>
    <div v-else-if="noData === 1" :style="{height:getHeight,lineHeight:getHeight}" class="t-echarts-content t-echarts-no-data">
      暂无数据
    </div>
    <div v-else-if="noData === 2" :style="{height:getHeight,lineHeight:getHeight}" class="t-echarts-content t-echarts-no-data">
      网络故障，请稍候
    </div>
  </div>
</template>

<script>
export default {
  name: "EchartsCard",
  props:{
    title:String,
    height:Number|String, // 内容高度
  },
  data(){
    return {
      noData:0 // 0:正常展示  1 没有数据 2 网络错误
    }
  },
  computed:{
    getHeight() {
      let h = window.innerHeight;
      return parseInt(this.height) / 1080 * 100 + "vh";
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.r-echarts-card{
  .t-echarts-top{
    border-left: sw(4) solid #1B7EF2;
    background-color: rgba(27, 126, 242, .14);
    height: sh(28);
    line-height: sh(28);
    .icon{
      color:#29F1FA;
      font-size: 18rem;
    }
    .t-title{
      color: white;
      font-size: sh(16);
      text-shadow: 0 2rem sh(24) rgba(26,125,242,0.91);
    }
  }
  .t-echarts-content{
    background-color: #061425;
    color:#cccccc;
  }
  .t-echarts-no-data{
    text-align: center;
    font-size: sh(12);
  }
}

</style>
