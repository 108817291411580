//全局过滤器
//处理日期 格式是年月日
export const normalizeYmd=(data)=>{
    let time=new Date(data*1000);
    let y=time.getFullYear();
    let M=time.getMonth()+1;
    let d=time.getDate();
    let h=time.getHours();
    let m=time.getMinutes();
    let s=time.getSeconds();
    let res = [y, M, d].map(v => v.toString().padStart(2, '0'));
    return res.join('.')+'  '+[h,m,s].map(v => v.toString().padStart(2, '0')).join(':')
}


//保留一位小数，且保留正负号
export const toFixedOne=(val)=>{
    let num = parseFloat(val)
    if(num < 0){
        return num.toFixed(1)
    }else if(num > 0){
        return '+' + num.toFixed(1)
    }else{
        return 0
    }
}
