<template>
  <div class="t-edit-template">
    <div :class="['t-edit-template-content',type==='default'?'t-edit-template-content-default':'t-edit-template-card']">
      <slot></slot>
    </div>
    <div class="t-edit-template-footer">
      <div v-if="!$slots.footer">
        <el-button class="btn" :loading="confirmLoading" type="primary" @click="$emit('confirm',$event)">{{confirmText}}</el-button>
        <el-button class="btn" @click="onCancel" :disabled="confirmLoading">{{cancelText}}</el-button>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-edit-template",
  props:{
    dataLoading:Boolean,
    // 页面标记: add:新增,edit:编辑, default:默认
    flag:{
      type:String,
      default:()=>"default",
      validator(value){
        return ["default","add","edit"].includes(value)
      }
    },
    // 类型: 默认或card
    type:{
      type:String,
      default(){
        return "default"
      },
      validator(value){
        return ["default","card"].includes(value)
      }
    },
    confirmLoading:Boolean,
    // 确认按钮文本
    confirmText:{
      type:String,
      default(){
        return "保存"
      }
    },
    // 取消按钮文本
    cancelText:{
      type:String,
      default(){
        return "取消"
      }
    },
    // 取消提示
    cancelRemind:{
      type:Boolean,
      default:true
    },
    // 取消提示文本
    cancelRemindText:{
      type:String,
      default(){
        return null
      }
    },
    // 是否为默认取消行为
    isDefaultCancel:{
      type:Boolean,
      default:true
    },
    // 取消弹窗配置
    cancelBoxOptions:{
      type:Object,
      default(){
        return {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      }
    }
  },
  data(){
    return {
      _dataLoading:null
    }
  },
  watch:{
    dataLoading(v){
      if (v){
        this.showDataLoading({text:"请稍后..."});
      }else{
        this.closeDataLoading();
      }
    }
  },
  computed:{
    cancelRemindMessage(){
      if (this.cancelRemindText){
        return this.cancelRemindText;
      }else{
        switch (this.flag){
          case "add":
            return "是否放弃新增？";
          case "edit":
            return "是否放弃编辑？";
          default:
            return "是否取消？";
        }
      }
    }
  },
  methods:{
    showDataLoading(options={}){
      let opt = Object.assign({text:"请稍后..."},options)
      this._dataLoading = this.$loading(opt);
    },
    closeDataLoading(){
      this._dataLoading&&this._dataLoading.close();
      this._dataLoading = null;
    },
    onCancel(){
      if (!this.cancelRemind) {
        this.$emit('cancel', null)
        return;
      }
      this.$confirm(this.cancelRemindMessage, '提示', this.cancelBoxOptions).then(() => {
        this.$emit('cancel',"confirm")
        if (this.isDefaultCancel)
          this.$router.back();
      }).catch(() => {
        this.$emit('cancel',"cancel")
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.t-edit-template{
  height:100%;
  padding-bottom: 0 !important;
  &-content{
    height: calc(100vh - 200rem);
    overflow-y: auto;

  }
  &-content-default{
    padding: 40rem;
  }
  &-content-card{
    padding: 0;
  }
  &-footer{
    line-height: 60rem;
    box-sizing: border-box;
    height:100rem;
    padding: 20rem 40rem;
    .btn{
      width:100rem;
    }
  }
}
</style>
