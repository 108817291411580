import tools from "utils/tools";
import {mapMutations,mapState} from "vuex";

/**
 * 列表页解析 , 返回getData方法
 */
function listPage(){
    // 如果路由不是以list结尾 则不是列表页
    if (!this.$route || this.$route.path.slice(-4) !== "list")
        return null;
    // 默认list页中包含getData方法
    let methods = this.$options.methods || {};
    let getData = methods.getData;
    if (getData){
        return getData
    }
    return null;
}
/**
 * 控制列表数据的展示插件
 * 在返回到列表页的时候 , 根据 prevOperation状态来判断页面是否需要刷新或重置
 * 并且为全局list实现了冗余函数和字段(不妨碍你自己写)
 * @author Training
 * @tips 只适用于本项目 , 只针对普通列表页有效
 * @description
 *      新增后: 重置页码, 重置搜索条件, 然后重置列表数据.
 *      编辑后: 重新获取当前页的列表数据
 *      查看后: 啥也不管
 * @rule
 *      1. 在列表页中必须添加getData方法
 *      2. 为了全局通用 , getData方法不能有任何参数
 *      3. 列表页的路由必须以list结尾
 */

export const listDataControl = {
    install(Vue){
        Vue.mixin({
            data(){
                return {
                    search:{},
                    total:0,
                }
            },
            computed:{
                ...mapState(["page"])
            },
            methods:{
                log:console.log,
                ...mapMutations(['setPage']),
                // 搜索
                onSearch(val){
                    this.setPage(1);
                    this.search = val;
                    this.$nextTick(this.getData);
                },
                // 翻页
                onPageChange(i){
                    this.setPage(i);
                    this.$nextTick(this.getData);
                }
            },
            // 当进入列表页 , 重置页码和上一次的操作状态
            beforeCreate() {
                let getData = listPage.call(this);
                if (getData){
                    this.$store.commit("setPage",1);
                    this.$setPrevOperation();
                }
            },
            // 当返回列表页时 , 根据prevOperation状态来判断是否需要刷新或重置
            activated() {
                let getData = listPage.call(this);
                if (getData){
                    let po = this.$store.state.prevOperation;
                    switch (po){
                        case "add": // 新增 重置搜索和页码
                            this.$store.commit("setPage",1);
                            try {
                                tools.BFS(this.$children,item=>{
                                    if (item.$refs.searchBlock && item.$el.className.includes("t-list")){
                                        throw item;
                                    }
                                },{
                                    child:"$children"
                                })
                            }catch (item){
                                item.$refs.searchBlock.reset(null);
                            }
                            break;
                        case "edit": // 编辑 重新获取数据
                            getData.call(this);
                            break;
                        default: // 默认 不做任何操作
                            break;
                    }
                    this.$setPrevOperation();
                }
            }
        })
    }
}
