/**
 * ajax工具函数层
 */
import axios from 'axios'
import axiosConfig from "./axios.config"
let axiosObj = axios.create({
  baseURL: process.env.VUE_APP_URL
});
axiosConfig(axiosObj);

//暴露配置好的axios
export default axiosObj
