<template>
  <scroll class="room-used" :hover="true" :scroll="useScroll">
    <div slot="fixed" class="header">
      <div class="item" v-if="legend.length > 0">
        <div class="color" :style="'background-image: linear-gradient(180deg, '+colors[0].colorStops[0].color+' 0%, '+colors[0].colorStops[1].color+' 100%);'"></div>
        <div class="text">{{ legend[0] }}</div>
      </div>
      <div class="item" v-if="legend.length > 1">
        <div class="color" :style="'background-image: linear-gradient(180deg, '+colors[1].colorStops[0].color+' 0%, '+colors[1].colorStops[1].color+' 100%);'"></div>
        <div class="text">{{ legend[1] }}</div>
      </div>
    </div>
    <echarts :style="{width}" ref="ec"/>
    <echarts :style="{width}" v-if="useScroll" ref="ec_clone"/>
  </scroll>
</template>

<script>
// 房间使用情况统计
import Echarts, {sh, sw} from "./Echarts";
import * as echarts from "echarts";
import Scroll from "./Scroll";

export default {
  name: "RoomUsed",
  data(){
    return{
      // echarts宽度
      width:"100%",
      // 学校列表
      legend:[],
      // 是否使用滚动
      useScroll:false,
      colors: [
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#1890FF' },
          { offset: 1, color: '#1890FF' }
        ]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#1EE7E7' },
          { offset: 1, color: '#1EE7E7' }
        ])
      ]
    }
  },
  components: {
    Echarts,
    Scroll
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){

      this.$_axios2.get("/api/visual-kanban/index/index",{
        params:{
          type:2,
          school_id:this.$route.query.school_id
        }
      }).then(res=>{
        let {status,msg} = res.data;
        if (status !== 0)return this.$parent.noData = 1;
        let room = res.data.data.room;
        let legend = this.legend = [];
        let xData = [];
        let yData = [[],[]];
        let data = {};
        room.forEach(item=>{
          let name = item.school_name;
          item.list.forEach(list=>{
            data[name] = data[name] || {};
            data[name][list.name] = list.number;
          })
        })
        for (const dataKey in data) {
          let item = data[dataKey];
          legend.push(dataKey);
          for (const itemKey in item) {
            if (xData.indexOf(itemKey)  === -1)
              xData.push(itemKey);
          }
        }
        // 渲染Y轴数据
        xData.forEach(key=>{
          legend.forEach((item,index)=>{
            yData[index].push(data[legend[index]][key])
          })
          // yData[0].push(data[legend[0]][key])
          // yData[1].push(data[legend[1]][key])
        })
        let useScroll = false;
        useScroll = this.useScroll = xData.length > 9;
        let grid = {
          top:sh(44),
          // bottom:sh(22),
          left:sw(useScroll?0:10),
          right:sw(useScroll?0:10),
          height:sh(100),
        };
        let series = legend.map((item,index)=>{
          return {
            barWidth:sw(14 / legend.length),
            name:item,
            data:yData[index],
            label:{
              show:true,
              position:"top",
              fontSize:sh(8)
            },
            barGap:.9,
            itemStyle:{
              color:this.colors[index]
            }
          }
        })
        this.width = useScroll?sw(xData.length * 60)+"rem":"100%";
        let options = {
          /*legend: {
            data: ['达州市鸿鹄高级中学', '资阳市鸿鹄高级中学'],
            top:sh(9),
            itemWidth:sw(10),
            itemHeight:sh(10),
            itemGap: 24, // 设置间距
            textStyle:{
              fontSize:sh(10)
            }
          },*/
          yAxis:{
            splitLine:{
              show:false,
              lineStyle:{
                type:[sw(40),sw(60)],
              },
            },
          },
          grid,
          xAxis:[{
            data:xData,
            axisLabel:{
              width:sw(60),
              overflow:"truncate",
              margin:sh(5),
              interval:0,
              fontSize:sh(10)
            },
            axisTick:{
              alignWithLabel:true,
            },
          }],
          series
        }
        this.$nextTick(()=>{
          this.$refs.ec.render(options)
          if (this.useScroll)
          this.$refs.ec_clone.render(options)
        })

      }).catch(err=>{
        const msg = err.message.toLowerCase();
        if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
          this.$parent.noData = 2
        else this.$parent.noData = 1;
      })


    }
  }
}
</script>

<style lang="scss" scoped>
.room-used{
  .header{
    position: relative;
    pointer-events: none;
    z-index: 999;
    color: white;
    display: flex;
    flex:1;
    justify-content: center;
    margin-top: sh(10);
    .item:nth-child(2){
      margin-left: sw(20);
    }
    .item{
      display: flex;
      align-items: center;
      .color{
        width: sw(10);
        height: sw(10);
        background-color: green;
        margin-right: sw(8);
      }
      .text{
        font-size: sh(10);
        color: #ccc;
      }
    }
  }
}
</style>
