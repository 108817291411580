<template>
  <div class="avg">
    <div class="avg-c1">
      <echarts class="avg1" ref="ec" v-if="!noData[0]"/>
      <div class="noData" v-else-if="noData[0]">
        暂无数据
      </div>
    </div>
    <div class="avg-c2">
      <echarts class="avg2" ref="ec1" v-if="!noData[1]"/>
      <div class="noData" v-else-if="noData[1]">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import Echarts,{sh,sw} from "./Echarts";
import * as echarts from "echarts";
export default {
  name: "average",
  components: {
    Echarts
  },
  mounted() {
    this.init();
  },
  data(){
    return{
      noData:[]
    }
  },
  methods:{
    init(){
      this.$_axios2.get("/api/visual-kanban/stu-attend-study/study-static",{
        params:{
          school_id:this.$route.query.school_id
        }
      }).then(res=>{
        let status = res.data.status;
        if (status !== 0){
          this.$parent.noData = 1;
        }else{
          let {average_scores_list} = res.data.data;
          let legend = [];
          let grades = [];
          let xData = [];
          let data = {};
          let noData = [];
          let index = -1;
          for (const averageScoresListKey in average_scores_list) {
            index ++;
            let grade = average_scores_list[averageScoresListKey];
            let g_name = grade.grade_name; // 届
            let xz = []; // x轴数据
            if (!grade.exam_data) {
              this.$set(this.noData,index,true);
              noData[index] = true;
              continue;
            }else{
              this.$set(this.noData,index,false);
              noData[index] = false;
            }
            for (const key in grade.exam_data) {
              let exam = grade.exam_data[key];
              xz.push(exam.exam_name);
              for (const schoolDataKey in exam.school_data) {
                let sd = exam.school_data[schoolDataKey]; // school data
                data[g_name] = data[g_name] || {};
                data[g_name][sd.school_name] = data[g_name][sd.school_name] || [];
                // data[g_name][sd.school_name] = data[g_name][sd.school_name] || [];
                let val = {
                  average:parseFloat(sd.school_average),
                  name:sd.school_name,
                };
                data[g_name][sd.school_name].push(parseFloat(sd.school_average));

              }
            }
            xData.push(xz);
          }
          if (noData[0] && noData[1])return this.$parent.noData = 1;
          for (const dataKey in data) {
            grades.push(dataKey);
            for (const school in data[dataKey]) {
              if (!legend.includes(school))
                legend.push(school)
            }
          }
          let colors = [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#1890FF' },
              { offset: 1, color: '#1890FF' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#1EE7E7' },
              { offset: 1, color: '#1EE7E7' }
            ])]
          let series = grades.map((g,i)=>{
            return legend.map((item,index)=>{
              return {
                name:legend[index],
                barWidth:sw(10),
                data:data[grades[i]][legend[index]],
                label:{
                  show:true,
                  position:"top",
                  fontSize:sh(10)
                },
                itemStyle:{
                  color:colors[index]
                }
              }
            })
          })
          let grid = {
            top:sh(66),
            // bottom:sh(22),
            left:sw(36),
            right:sw(42),
            height:sh(80)
          };
          let options = {
            legend: {
              data: legend,
              top:sh(9),
              itemWidth:sw(10),
              itemHeight:sw(10),
              itemGap: 24, // 设置间距
              textStyle:{
                fontSize:sh(10)
              }
            },
            title:{
              top:sh(38),
              text:grades[0]+"学生成绩平均数变化情况",
              textStyle:{
                fontSize: sh(12),
              },
              padding:[0,0,0,sw(10)]
            },

            yAxis:{
              splitLine:{
                show:false,
                lineStyle:{
                  type:[sw(40),sw(60)],
                },
              },
              max(val){
                return val.max + 10;
              }

            },
            grid,
            xAxis:[{
              data:xData[0],

              axisLabel:{
                width:sw(60),
                overflow:"truncate",
                margin:sh(5),
                interval:0,
                fontSize:sh(10)
              },
              axisTick:{
                alignWithLabel:true,
              },
            }],
            series:series[0]
          }
          let options1 = {
            title:{
              top:sh(20),
              text:grades[1]+"届学生成绩平均数变化情况",
              textStyle:{
                fontSize: sh(12)
              },
              padding:[0,0,0,sw(10)]
            },
            yAxis: {
              splitLine: {
                show:false,
                lineStyle:{
                  type:[sw(40),sw(60)],
                },
              },
              max(val){
                return val.max + 10;
              }
            },
            grid:{
              ...grid,
              top:sh(50)
            },
            xAxis:[{
              data:xData[1],

              axisLabel:{
                width:sw(60),
                overflow:"truncate",
                margin:sh(5),
                interval:0,
                fontSize:sh(10)
              },
              axisTick:{
                alignWithLabel:true,
              },
            }],
            series:series[1]
          }
          let optionArr = [options,options1];
          let ecArr = [this.$refs.ec,this.$refs.ec1];
          noData.forEach((item,index)=>{
            if (index > 1) return;
            let ec = ecArr.shift();
            if (!item){
              let opt = optionArr.shift();
              ec.render(opt);
            }
          })
          // if (len === 1)
          // this.$refs.ec.render(options)
          // if (len > 1)
          // this.$refs.ec1.render(options1)

        }
      }).catch(err=>{
        const msg = err.message.toLowerCase();
        if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
          this.$parent.noData = 2
        else this.$parent.noData = 1;
      })


    }
  }

}
</script>

<style lang="scss" scoped>
.avg{
  width: 100%;
  height: 100%;
  .avg-c1{
    text-align: center;
    height: sh(167);
    line-height: sh(167);
  }
  .avg-c2{

    text-align: center;
    height: sh(175);
    line-height: sh(175);
  }
  .avg1{
    height: sh(167);
  }
  .avg2{
    height: sh(175);
  }
  .noData{
    color:#ccc;
    font-size: sh(12);
  }
}
</style>
