<template>
  <div id="echarts" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
import {sh, sw} from "./Echarts";

export default {
  name: "YesterdayTeacherNotWork",
  props: ['data'],
  mounted() {
    this.$_axios2('/api/visual-kanban/index/work?school_id=' + this.$route.query.school_id).then(res => {
      // console.log(res)
      if (res.data.status !== 0){
        return this.$parent.noData = 1;
      }else{
        let data = res.data.data
        if(data.length){
          this.lineInit(data)
        }
      }
    }).catch(err=>{
      const msg = err.message.toLowerCase();
      if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
        this.$parent.noData = 2
      else this.$parent.noData = 1;
    })
  },
  methods: {
    lineInit(lineData) {
      // return;
      //mock-data
      // lineData = [
      //   {
      //     "school_id": 1,
      //     "total": 70,
      //     "school_name": "达州鸿鹄高级中学"
      //   },
      //   {
      //     "school_id": 2,
      //     "total": 90,
      //     "school_name": "资阳鸿鹄高级中学"
      //   },
      // ]

      let chartDom = this.$refs['echarts'];
      let myChart = echarts.init(chartDom)
      let colorList = ['#1890FF', '#41E4BB'];
      let count = 0
      let sw_height = [sh(64),sh(46)]

      let data = [];
      if (lineData.length) {
        data = lineData.map(item => {
          count += item.total
          return {value: item.total, name: item.school_name}
        })
      }

      let option = {
        color: colorList,
        legend: {
          icon: 'roundRect',
          selectedMode: false,
          orient: 'vertical',
          top: sw_height[data.length - 1],
          left: sw(50),
          itemWidth: sw(10),
          itemHeight: sh(10),
          itemGap: 24, // 设置间距
          textStyle: {
            fontSize: sw(14),
            color: '#bbb',
          }
        },
        legendHoverLink: false,
        series: {
          name: 'Access From',
          type: 'pie',
          right: '-40%',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            color: '#fff',
            formatter: () => {
              return count
            }
          },
          emphasis: {
            disabled: true
          },
          labelLine: {
            show: false
          },
          data
        }
      }

      option && myChart.setOption(option, {
        notMerge: true
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#echarts {
  height: 100%;
}
</style>
