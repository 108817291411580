<template>
  <div style='height: calc(100%);width: 100%;'>
    <div class='title_name'>
      <span style='padding: 0 12rem;' v-for='item in school_name'>{{ item }}</span>
    </div>
    <div id='CurriculumStatistics' style='height: calc(100%);width: 100%;'></div>
    <div style='height: calc(100%);width: 100%;' v-if='false'></div>
  </div>

</template>

<script>
import * as echarts from 'echarts';
import { sh, sw } from "@/views/DataVisualization/components/Echarts";

export default {
  name: 'CurriculumStatistics',
  data() {
    return {
      school_name: []
    };
  },

  methods: {
    // 获取屏幕宽度并计算比例
    fontSize(px) {
      let clientWidth = window.innerHeight || document.body.clientHeight; //屏幕尺寸
      if (!clientWidth) {
        return 0;
      }
      let fontSize = clientWidth / 1080; //设计稿尺寸
      return px * fontSize;  //转换为 rem的布局返回出去
    },
  },

  mounted() {
    this.$_axios2.get('api/visual-kanban/calendar/today-static', { params: { school_id: this.$route.query.school_id } }).then(res => {
      if (res.data.status === 0) {
        let chartDom = document.getElementById('CurriculumStatistics');
        let myChart = echarts.init(chartDom);
        let option
        if (res.data.data.school_static.length === 2) {
          option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: () => ''
            },
            legend: {
              itemWidth: sw(10),
              itemHeight: sw(10),
              selectedMode: false, // 是否允许点击
              textStyle: {
                color: '#ccc',//字体颜色
                fontSize: sh(10)
              },
            },
            label: {
              fontSize: sh(10),
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              top: this.fontSize(24),
              containLabel: true,
            },
            xAxis: {
              type: 'value',
              splitArea: {},
              boundaryGap: [0, 0.01],
              axisTick: { show: true },
              axisLine: { show: true, },
              splitLine: { show: false },
              max: res.data.data.lesson_num_max,
            },
            yAxis: {
              type: 'category',
              axisTick: { show: false },
              splitLine: { show: false },
              data: res.data.data.subject_static.reverse(),
              axisLabel: {
                textStyle: {
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: sh(10)
                }
              },
            },
            series: [
              {
                name: res.data.data.school_static[0].school_name,
                type: 'bar',
                data: res.data.data.school_static[0].subject_lesson_num.reverse(),
                showBackground: true,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1890FF' },
                      { offset: 1, color: '#416f97' }
                    ]),
                    label: {
                      align: 'top',
                      show: true,
                      position: 'right',
                      textStyle: {
                        color: 'rgba(255, 255, 255, 1)', size: '0.16rem',
                      },
                    },
                  },
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1890FF' },
                      { offset: 1, color: '#416f97' }
                    ]),
                  }
                },
              },
              {
                name: res.data.data.school_static[1].school_name,
                type: 'bar',
                data: res.data.data.school_static[1].subject_lesson_num.reverse(),
                showBackground: true,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1EE7E7' },
                      { offset: 1, color: '#1c6b74' }
                    ]),
                    label: {
                      align: 'top',
                      show: true,
                      position: 'right',
                      textStyle: {
                        color: 'rgba(255, 255, 255, 1)', size: '0.16rem'
                      },
                    },
                  },
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1EE7E7' },
                      { offset: 1, color: '#1c6b74' }
                    ])
                  }
                },
              },
            ]
          };
        } else {
          option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: () => ''
            },
            legend: {
              itemWidth: sw(10),
              itemHeight: sw(10),
              selectedMode: false, // 是否允许点击
              textStyle: {
                color: '#ccc',//字体颜色
                fontSize: sh(10)
              },
            },
            label: {
              fontSize: sh(10),
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              top: this.fontSize(24),
              containLabel: true,
            },
            xAxis: {
              type: 'value',
              splitArea: {},
              boundaryGap: [0, 0.01],
              axisTick: { show: true },
              axisLine: { show: true, },
              splitLine: { show: false },
              max: res.data.data.lesson_num_max,
            },
            yAxis: {
              type: 'category',
              axisTick: { show: false },
              splitLine: { show: false },
              data: res.data.data.subject_static.reverse(),
              axisLabel: {
                textStyle: {
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: sh(10)
                }
              },
            },
            series: [
              {
                name: res.data.data.school_static[0].school_name,
                type: 'bar',
                data: res.data.data.school_static[0].subject_lesson_num.reverse(),
                showBackground: true,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1890FF' },
                      { offset: 1, color: '#416f97' }
                    ]),
                    label: {
                      align: 'top',
                      show: true,
                      position: 'right',
                      textStyle: {
                        color: 'rgba(255, 255, 255, 1)', size: '0.16rem',
                      },
                    },
                  },
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      { offset: 0, color: '#1890FF' },
                      { offset: 1, color: '#416f97' }
                    ]),
                  }
                },
              },
            ]
          };
        }
        option && myChart.setOption(option);
      } else {
        this.$parent.noData = 1;
      }
    }).catch(err => {
      const msg = err.message.toLowerCase();
      if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
        this.$parent.noData = 2
      else this.$parent.noData = 1;
    });
  }
};
</script>

<style scoped lang='scss'>
.title_name {
  width: sw(480);
  font-size: 14rem;
  position: absolute;
  text-align: center;
  padding-left: 36rem;
  z-index: 999999999999999999;
  height: sh(320);
}
</style>
