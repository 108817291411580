<template>
  <div style='height: calc(100% - 0rem);width: 100%;'>
    <div id='StudentStatistics' style='height: calc(100% - 0rem);width: 100%;'></div>
    <div style='height: calc(100% - 0rem);width: 100%;' v-if='false'></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { sh, sw } from "@/views/DataVisualization/components/Echarts";

export default {
  name: 'StudentStatistics',

  methods: {
    // 获取屏幕宽度并计算比例
    fontSize(px) {
      let clientWidth = window.innerHeight || document.body.clientHeight; //屏幕尺寸
      if (!clientWidth) {
        return 0;
      }
      let fontSize = clientWidth / 1080; // 设计稿尺寸
      return px * fontSize;  // 转换为 rem的布局返回出去
    },
  },

  mounted() {
    this.$_axios2.get('api/visual-kanban/index/student', { params: { school_id: this.$route.query.school_id } }).then(res => {
      if (res.data.status === 0) {
        let schoolTotal = []; // 有哪些学校
        res.data.data.school.forEach(i => {
          schoolTotal.push(i.school_name + '学生总数:' + i.total);
        });
        let schoolData = []; // 学校数据

        res.data.data.school_grade.forEach(i => { // echarts渲染所需数据提取
          res.data.data.school.forEach(i_ => {
            if (i.school_name === i_.school_name) {
              schoolData.push({
                name: i.school_name + '学生总数:' + i_.total,
                data: [i.total],
                type: 'bar',
                school_name: i.school_name,
                grade_name: i.grade_name,
                showBackground: true,
                schoolType: i.school_id,
                itemStyle: { // 设置样式
                  normal: {
                    color: new echarts.graphic.LinearGradient(1, 1, 0, 1, [{
                      offset: 0,
                      color: 'rgba(24, 144, 255, 1)' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 0.3500)' // 100% 处的颜色
                    }], false),
                    label: {
                      show: true,
                      align: 'left',
                      position: 'inside',
                      padding: [0, 0, 0, this.fontSize(6)],
                      formatter: i.school_name + i.grade_name + ' ' + '{c}' + '名',
                      textStyle: { size: sh(10) }
                    },
                  },
                },
              });
            }
          })
        });
        schoolData.forEach((item, i) => { // 提取相同学校id添加相同name实现点击同时隐藏
          if (schoolData[i].schoolType !== schoolData[0].schoolType) {
            item.itemStyle.normal.color.colorStops[0].color = 'rgba(30, 231, 231, 1)';
            item.itemStyle.normal.color.colorStops[1].color = 'rgba(30, 231, 231, 0.3500)';
          }
          schoolData.forEach((item_, j) => {
            if (schoolData[i].schoolType === schoolData[j].schoolType) {
              schoolData[i].name = schoolData[j].name;
            }
          });
        });
        let chartDom = document.getElementById('StudentStatistics');
        let myChart = echarts.init(chartDom);
        let option = {
          tooltip: { // 开启悬浮展示
            formatter: () => '',
            axisPointer: {
              type: 'shadow'
            }, trigger: 'item'
          },
          grid: {
            bottom: '0',
            containLabel: true,
            top: this.fontSize(24),
            left: this.fontSize(12),
          },
          xAxis: {
            show: false,
            max: res.data.data.max,
            axisTick: { show: false },
            axisLabel: { show: false }
          },
          yAxis: {
            type: 'category',
            show: true,
            axisLine: { show: true },
            splitArea: { show: false },
            axisLabel: { show: false },
            axisTick: {
              show: false,
              alignWithLabel: false,
            },
          },
          label: {
            fontSize: sh(10),
          },
          series: schoolData,
          legend: {
            show: true,
            top: sh(9),
            itemWidth: sw(10),
            itemHeight: sw(10),
            formatter: function (name) {
              return name;
            },
            data: schoolData,
            textStyle: {
              color: '#cccccc',//字体颜色
              fontSize: sh(10)
            },
            selectedMode: false, // 是否允许点击
          },
        };
        option && myChart.setOption(option);
      } else {
        this.$parent.noData = 1;
      }
    }).catch(err => {
      const msg = err.message.toLowerCase();
      if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
        this.$parent.noData = 2
      else this.$parent.noData = 1;
    });
  }
};
</script>

<style scoped>

</style>
