import axios from "./request";
import axiosNative from "axios";
import axios2 from "./request_2.js";
import tools from "./tools";
import store from "../store";
import axios3 from "./request_3";
import register from "./axios.register";
import api2 from "./api2";

const {error} = tools;
/**
 * @desc 登录接口
 * @param username 用户名
 * @param password  密码
 * @returns {Promise<AxiosResponse<any>>}
 */
const login = (username = tools.error("username"), password) => {
  return axios2.post("api/account/login", {
    login_type: 2,
    username,
    password
  }, {
    isLogin: true,
    logic:1
  })
}
/**
 * @description 获取登录二维码
 * @param  type
 */
const getQRCode = (type = error("type")) => {
  let host = window.location.origin;
  let redirect = encodeURIComponent(host + `/login`);

  return new Promise((resolve, reject) => {
    switch (type) {
      case "微信":
        break;

      case "企微":
        // 218.89.142.132:8000
        resolve("https://open.work.weixin.qq.com/wwopen/sso/qrConnect?login_type=jssdk&appid=ww47d0ecb228c2ed20&agentid=1000011&redirect_uri=" + redirect + "&state=sip_Wxlogin&href=&lang=zh&version=1.2.5");
        break;
      case "钉钉":
        // 回调 URL
        let goto = encodeURIComponent('https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingrmvtrcdhdmaeykd8&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=' + redirect)
        const config = {
          goto: goto, //请参考注释里的方式
          style: "border:none;background-color:#FFFFFF;margin:0;",
        }
        let d = "https://login.dingtalk.com/login/qrcode.htm?goto=" + config.goto;
        d += config.style ? "&style=" + encodeURIComponent(config.style) : "";
        d += config.href ? "&href=" + config.href : "";
        resolve(d)
        let handleMessage = function (event) {
          let origin = event.origin;
          if (origin == "https://login.dingtalk.com") { //判断是否来自ddLogin扫码事件。
            let loginTmpCode = event.data;
            window.location.href = "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=ww47d0ecb228c2ed20&response_type=code&scope=snsapi_login&state=Dinglogin&redirect_uri=" + redirect + "&loginTmpCode=" + loginTmpCode;
          }
        };
        if (typeof window.addEventListener != 'undefined') {
          window.addEventListener('message', handleMessage, false);
        } else if (typeof window.attachEvent != 'undefined') {
          window.attachEvent('onmessage', handleMessage);
        }
        break;
    }
  })
}

/**
 * @description 获取登录回调
 * @param code
 * @param type 1.微信 2.企微 3.钉钉
 * @returns {Promise<AxiosResponse<any>>}
 */
function getLoginCallback(code = null, type = 1) {
  return axios.get("/auth/callback?code=" + code + "&type=" + type);
}

/**
 * @description 绑定账号
 * @param type 1.微信 2.企微 3.钉钉
 * @param openid 第三方唯一标识
 * @param username 用户名
 * @param password 用户密码
 * @returns {Promise<AxiosResponse<any>>}
 */
function bindAccount(type = 1, openid = error("openid"), username = error("username"), password = error("password")) {
  return axios.post("/auth/binding", {
    type,
    openid,
    username,
    password
  });
}

/**
 * @description 退出登录
 * @return {Promise<AxiosResponse<any>>}
 */
function logout() {
  return axios.post("/auth/logout");
}

/**
 * @description 切换角色
 * @param id
 */
function toggleRole(id = error("id")) {
  return axios.get("/site/switch?id=" + id);
}

/**
 * @description 清空缓存的登录信息
 */
function clearLoginData() {
  store.commit("setUserInfo", null);
  store.commit("setToken", null);
  store.commit("setMenuConfig", []);
}

/**
 * @description 修改密码
 * @param old_password 旧密码
 * @param password 新密码
 * @param re_password 确认密码
 * @return {Promise<AxiosResponse<any>>}
 */
function modifyPassword({old_password, password, re_password}) {
  return axios.post("/auth/changepwd", {
    old_password, password, re_password
  })
}

/**
 * @description 获取菜单权限
 * @return {Promise<AxiosResponse<any>>}
 */
function getMenu() {
  return axios2.get("api/permission/menu",{logic: 1});
}


/**
 * 获取按钮权限
 * @param {number} menu_id
 * @return {Promise<AxiosResponse<any>>}
 */
function getButtonPermissions(menu_id) {
  return axios2.get("api/permission/button",{logic: 1,params:{menu_id}});
}
let browser = tools.GetBrowser();
if (browser.name === "IE") {

}

/**********************************************************登录 End  角色 Start*******************************************************************/
/**
 * @description 获取角色类型
 * @return {Promise<AxiosResponse<any>>}
 */
function getRoleType() {
  return axios.get("/site/role-type");
}

/**
 * @description 获取菜单权限配置列表 (角色管理处)
 * @return {Promise<AxiosResponse<any>>}
 */
function getMenuConfigList(type) {
  return axios.get("/site/menu", {
    params: {
      type
    }
  });
}

/**
 * @description 校区列表 (角色管理处)
 * @return {Promise<AxiosResponse<any>>}
 */
function getCampusList() {
  return axios.get("/site/school");
}

/**
 * @description 按校区获取班级数据
 * @param {string} school_ids 校区id集合 逗号隔开
 * @return {Promise<AxiosResponse<any>>}
 */
function getClassesList(school_ids, menu_ids) {
  return axios.post("/site/classes", {school_ids, menu_ids});
}

/**
 * 获取角色类型
 * @return {Promise<AxiosResponse<any>>}
 */
function getRole(){
  return axios2.get("api/permission/account-role",{logic: 1});
}
/*****************************************************公用api********************************************************************/
/**
 * @description 获取年级列表
 * @return {Promise<AxiosResponse<any>>}
 */
function getGradeList() {
  return axios.get("/site/grades");
}

/**
 * @description 获取班级
 * */
function getClassList(class_type, school_id, grade_id) {
  return axios.post("/attendance/get-class-list", {
    class_type,
    school_id,
    grade_id
  })
}

/**
 * @description 获取校区列表
 * @return {Promise<AxiosResponse<any>>}
 */
function getSchoolList() {
  return new Promise((resolve, reject) => {
    let userinfo = tools.getSession("USER_INFO");
    resolve(userinfo.school_arr)
  })
}

function uploadToOSS(file) {
  return new Promise(((resolve, reject) => {
    axios2.post("/api/upload/get-upload-addr").then(res => {
      console.log(res)
      let {upload_addr} = res.data.data;
      axiosNative.put(upload_addr, file, {
        headers: {
          "Content-Type": file.type
        }
      }).then(_ => {
        resolve(upload_addr.split("?")[0])
      }).catch(err => {
        // 上传失败的原因可能是上传链接失效, 但是这个可能性几乎不会出现
        reject({
          data: "",
          msg: "上传失败",
          status: 403
        })
      })
    }).catch(err => {
      // 获取上传链接失败
      reject(err)
    })
  }))
}

//此方法修改了图片上传的地址，是2023.5.11王伟让改的，记录在钉钉研发组
function uploadToOSS_1(file, type, use_outer_url = '') {
  return new Promise(((resolve, reject) => {
    let formData = new FormData();
    formData.append("upload",file);
    formData.append("type",type);
    if(use_outer_url){
      formData.append("use_outer_url",use_outer_url);
    }
    axios2.post("/api/site/upload", formData).then(res => {
    // axios2.post("https://main.test.honghueducation.com:8003/api2/api/site/upload", formData).then(res => {
      resolve(res.data.data.file_addr)
    }).catch(err => {
      // 获取上传链接失败
      reject(err)
    })
  }))
}

/**
 * 从OSS中删除文件
 * @param filename 文件地址
 * @param type 1:"images" 2 : bucket_template 3: recruit-image
 * @return {Promise<AxiosResponse<any>>}
 */
function deleteFile(filename,type=1){
  return axios2.post("api/upload/del-file",{
    filename,
    type
  },{logic:1})

}
// 新角色管理 获取角色类型
const getRoleLevel = ()=>{
  return axios2.get("/api/permission/role-level")
}
// 新角色管理 - 新增 - 获取菜单配置列表
const getMenuConfigList1 = (level)=>{
  return axios2.get("/api/permission/menu-list",{
    params: {level},
    logic:1
  })
}
// 新角色管理 - 新增 - 获取年级列表
const getGradeList1 = ()=>{
  return axios2.get("/api/permission/grade-list",{logic:1});
}
// 新角色管理 - 新增 - 获取科目列表
const getSubjectList1 = ()=>{
  return axios2.get("api/permission/subject-list", {logic: 1});
}
// 页面操作按钮包含
const pageHandleButton = new Set(["add","import","export"])
// 列表操作按钮包含
// const listHandleButton = new Set(["edit","detail","delete"])
/**
 * 通过关键键和关键值,从searchConfig中查找对应的数据对象 , 然后更新其options
 *  更新搜索配置的option
 *  使用方式 , 通过apply或者call等方式绑定this调用
 * @param key 关键key
 * @param value 关键值
 * @param options 需要注入的option
 * @param config 搜索配置字段的key
 */
function updateOptions(key,value,options = null,config="searchConfig"){
  let searchConfig = this[config]
  for (let i in searchConfig){
    let item = searchConfig[i];
    if (item[key] === value){
      if (options)
        this.$set(this[config][i],"options",options);
      return item.options;
    }
  }
}

function getOptions(key,value,config="searchConfig"){
  let searchConfig = this[config]
  for (let i in searchConfig){
    let item = searchConfig[i];
    if (item[key] === value){
      return item.options;
    }
  }
}

/**
 * @description DFS遍历路由树
 * @param node
 * @return {{}}
 * @constructor
 */
export function DFSRouter(node){
  let names = {};
  if (node != null) {
    let stack = [];
    for (let i = node.length-1;i>=0;i--)stack.push(node[i]);
    while (stack.length !== 0) {
      try {
        let item = stack.pop();
        let title = item.meta.title;
        let children = item.children;
        if (children)names[title] = item.path;
        else children = []; //
        for (let i = children.length - 1; i >= 0; i--)
          stack.push(children[i]);
      }catch (e) {
        debugger;
      }

    }
  }
  return names;
}
/**
 * 根据屏幕比例计算大小
 * @param size 要计算的标准值
 * @return {number} 返回计算后的值
 */
function ss(size){
  let rate = tools.calcRem(1920,1440,false);
  return size * rate;
}
// 空白方法（作占位用）
export function spaceFunction(){}

/**
 * 设置上一次的操作值
 * @param {"add"|"edit"|"delete"|"none"} state
 */
export function setPrevOperation(state="none"){
  store.commit("setPrevOperation",state)
}
// 角色相关的api
const roleApi = {
  getRoleType,
  getMenuConfigList,
  getMenuConfigList1,
  getGradeList1,
  getSubjectList1,
  getCampusList,
  getClassesList,
  getRoleLevel,
}
// 登录相关的api
const loginApi = {
  login,
  getQRCode,
  getLoginCallback,
  bindAccount,
  logout,
  clearLoginData,
  toggleRole,
  getMenu,
  modifyPassword,
  getButtonPermissions,
  getRole
}
// 公共api
const commonApi = {
  getGradeList,
  getSchoolList,
  getClassList
}
export const utils = {
  loginApi,
  roleApi,
  commonApi,
  updateOptions,
  getOptions,
  DFSRouter,
  pageHandleButton,
  ss
}
export default {
  install(vue, option) {
    vue.prototype.$utils = utils;
    vue.prototype.$_axios = axios;
    vue.prototype.$api1 = axios;
    vue.prototype.$_axios2 = axios2;
    vue.prototype.$api2 = axios2;
    vue.prototype.$_axios3 = axios3;
    vue.prototype.$_register = register;
    vue.prototype.$tools = tools;
    vue.prototype.api2 = api2
    vue.prototype.$uploadToOSS = uploadToOSS;
    vue.prototype.$uploadToOSS_1 = uploadToOSS_1;
    vue.prototype.$deleteFile = deleteFile;
    vue.prototype.$setPrevOperation = setPrevOperation;
  }
}
