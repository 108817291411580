/**
 * 从数组中获取指定id的项
 * @author Training
 * @example
 *      let options = [{id:1,name:"选项1"},{id:2,"选项2"}];
 *      let name = options.getOption(2).name;
 * @param keyword 查找关键字
 * @param {{key?:string,first?:boolean}} params
 * @return {Array<Object>|Object}
 */
Array.prototype.getOption = function (keyword,params={}) {
    let p = Object.assign({
        key:'id', // 使用什么字段名查找数据
        first:true // 是否返回第一个, 真则反回一个对象 , 否则返回一个数组
    },params);
    let {key,first} = p;
    let res = this.filter(e=>e[key]===keyword);
    if (first){
        return res.length?res[0]:{};
    }else return res;
}
