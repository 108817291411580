import axios from "axios";
import tools from "utils/tools";
import router from '../router'
import elem from "element-ui";

let axiosConfig = axios.create({
    baseURL: process.env.VUE_APP_URL_ONE,
    timeout: 10000
})

axiosConfig.interceptors.request.use(config => {
    let token = tools.getSession(tools.constants.token)
    let traceid = tools.getSession('traceId')
    if (token) {
        config.headers.authorization = 'Bearer ' + token
        if (!!traceid) config.headers.traceid = traceid
    }
    if (config.headers.timeout) {
        config.timeout = config.headers.timeout
    }
    return config
})

axiosConfig.interceptors.response.use(response => {
    if (response.data.status === 0)
        return Promise.resolve(response.data.data)
    if (response.data.status === 100)
        return router.push('/login').then()
    if (response.data.status !== 0) {
        elem.Message.error({message: response.data.msg, showClose: true})
        return Promise.reject(response.data.data)
    }
})

export default axiosConfig;