<template>
  <li class="t-menu-item" :class="{'t-menu-item-active':rootMenu.currentActive===index}" :style="rootMenu.currentActive===index?activeStyle:''" @click="onChange">
    <slot></slot>
    <div :class="['t-menu-item-line','t-menu-item-line-'+rootMenu.mode]" v-show="rootMenu.currentActive===index && line"></div>
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  inject:{
    'rootMenu':{
      default:null
    },
    'subMenu':{
      default:null
    }
  },
  props:{
    index:String,
    activeStyle:String|Object,
    line:Boolean
  },
  mounted() {

  },
  methods:{
    onChange(){
      let {router} = this.rootMenu;
      this.$emit("click",this.index);
      if (router){
        this.$router.push(this.index);
        if (this.subMenu && !this.subMenu.isActive){
          this.subMenu.isActive = true;
          this.rootMenu.subMenu.isActive = false;
          this.rootMenu.subMenu = this.subMenu;
        }
        // this.rootMenu.$emit('change',this.index);
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_setting.scss";
.t-menu-item{
  //height: 48rem;
  line-height: 48rem;
  padding: 0 20rem;
  cursor: pointer;
  font-size: 14rem;
  color:$menuItem;
  position: relative;
  .t-menu-item-line{
    background-color: $primary;
    position: absolute;
  }
  .t-menu-item-line-vertical{
    right: 0;
    height: 80%;
    bottom: 10%;
    width:4rem;
    border-radius: 2rem;
  }
  .t-menu-item-line-horizontal{
    right: 5%;
    height: 4rem;
    bottom: 0;
    width:90%;
    border-radius: 2rem;
  }
}
.t-menu-item:hover{
  background-color: $menuItem-hover;
}
.t-menu-item.t-menu-item-active{
  color: $menuItem-active-color;
  //border-bottom: 4rem solid $menuItem-active-bg;
}
.t-menu .t-submenu{
  .t-menu-item{
    padding-left: 40rem;
  }
}
</style>
