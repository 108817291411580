<template>
  <div class="t-menu-item-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MenuItemGroup"
}
</script>

<style scoped>

</style>