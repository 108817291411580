import {Autocomplete, Cascader, DatePicker, Input} from 'element-ui';
import BigDataSelect from './BigDataSelect';
import BigDataSelectItem from './BigDataSelectItem';
import Card from './Card';
import Dialog from './Dialog';
import Menu from './Menu';
import MenuItem from './MenuItem';
import MenuItemGroup from './MenuItemGroup';
import addEnterEvent from './override/addEnterEvent';
// import Button from "./Button.vue";
import Button from './override/Button.js';
import Result from './Result';
import SubMenu from './SubMenu';
import ListTemplate from './pages/List';
import EditTemplate from "./pages/Edit"
import TableTo from './TableTo';
import Textarea from './Textarea';
import Select from "./override/Select";
import HistoryDialog from "./HistoryDialog";
import HistoryToDialog from './HistoryToDialog'
import MainPage from './common/MainPage.vue'
addEnterEvent([Input, Select, Cascader, Autocomplete, DatePicker]);
const components = {
  install: function(vue) {
    vue.component('ListTemplate', ListTemplate);
    vue.component('EditTemplate', EditTemplate);
    vue.component('t-menu', Menu);
    vue.component('t-menu-item', MenuItem);
    vue.component('t-submenu', SubMenu);
    vue.component('t-menu-item-group', MenuItemGroup);
    vue.component('t-big-data-select', BigDataSelect);
    vue.component('t-big-data-select-item', BigDataSelectItem);
    vue.component('t-card', Card);
    vue.component('t-textarea', Textarea);
    vue.component('t-result', Result);
    vue.component('l-table', TableTo);
    vue.component('el-dialog', Dialog);
    vue.component('el-button', Button);
    vue.component("el-select", Select);
    vue.component("h-dialog", HistoryDialog);
    vue.component("l-history-dialog", HistoryToDialog);
    vue.component('ZMainPage', MainPage)
  },
};
export default components;
