import Vue from "vue"
import HistoryDialog from "@/components/HistoryDialog";
import {PopupManager} from "element-ui/src/utils/popup";
import {spaceFunction} from "utils/utils";
let HistoryConstructor = Vue.extend(HistoryDialog);

/**
 * 查看历史记录全局方法
 * @param {{data?:Array, onClose?: () => void}} options 选项信息
 * @return {Object & Record<never, any> & {historyList: any[], historyDialogShow: boolean} & Vue}
 * @constructor
 */
function HistoryRecord (options) {
    options = options || {};
    let props = {
        historyList:options.data||[],
        historyDialogShow:true
    };
    let onClose = options.onClose || spaceFunction;
    let instance = new HistoryConstructor({propsData:props});
    instance.$mount();
    instance.$el.style.zIndex = PopupManager.nextZIndex();
    document.body.appendChild(instance.$el);
    instance.$on("close",()=>{
        instance.$props.historyDialogShow = false;
        document.body.removeChild(instance.$el);
        onClose();
    })
    return instance;
}
export default HistoryRecord;
