<template>
  <div class="data-visualization" >
    <div class="top" :style="{backgroundImage:'url('+oss+'data-visualization/header.png)'}"></div>
    <div class="content" :style="{backgroundImage:'url('+oss+'data-visualization/map.png)' }">
      <div class="left">
        <echarts-card
            class="ec"
            title="学校在校学生情况统计"
            height="147"
        >
	        <StudentStatistics/>
        </echarts-card>
        <echarts-card
            class="ec"
            title="学校教师在职情况统计"
            height="150"
        >
          <working/>
        </echarts-card>
        <echarts-card
            class="ec"
            title="平均数变化情况"
            height="340"
        >
          <average/>
        </echarts-card>
        <echarts-card
            class="ec"
            title="寝室入住情况统计"
            height="152"
        >
	        <DormitoryOccupancy />
        </echarts-card>
      </div>
      <div class="center">
        <div class="map">

        </div>
        <div class="bottom">
          <echarts-card
              class="ec"
              title="房间使用情况统计"
              height="168"
          >
            <room-used/>
          </echarts-card>
          <echarts-card
              class="ec"
              title="学校楼宇使用统计情况"
              height="168"
          >
            <BuildingUsed></BuildingUsed>
          </echarts-card>
        </div>
      </div>
      <div class="right">
        <echarts-card
            class="ec"
            title="今日课程统计"
            height="340"
        >
	        <CurriculumStatistics />
        </echarts-card>
        <echarts-card
            class="ec"
            title="近7日一对一辅导统计"
            height="150"
        >
          <SevenDayOneToOne></SevenDayOneToOne>
        </echarts-card>
        <echarts-card
            class="ec"
            title="昨日班主任未完成工作情况统计"
            height="150"
        >
          <YesterdayTeacherNotWork></YesterdayTeacherNotWork>
        </echarts-card>
        <echarts-card
            class="ec"
            title="近7日考勤异常学生数量统计"
            height="150"
        >
          <SevenDayAttendance></SevenDayAttendance>
        </echarts-card>
      </div>
    </div>
  </div>
</template>

<script>
import EchartsCard from "./components/EchartsCard";
import Vertical from "./components/Echarts";
import Working from "./components/Working";
import Average from "./components/Average";
import SevenDayOneToOne from "./components/SevenDayOneToOne";
import SevenDayAttendance from "./components/SevenDayAttendance";
import YesterdayTeacherNotWork from "./components/YesterdayTeacherNotWork";
import BuildingUsed from "./components/BuildingUsed";
import CurriculumStatistics from './components/CurriculumStatistics'
import DormitoryOccupancy from './components/DormitoryOccupancy'
import StudentStatistics from './components/StudentStatistics'
import RoomUsed from "./components/RoomUsed";
export default {
  data(){
    return {
      scale:1,
      dataCont: '',
      oss:process.env.VUE_APP_URL_OSS
    }
  },
  components:{
    EchartsCard,
    SevenDayOneToOne,
    SevenDayAttendance,
    YesterdayTeacherNotWork,
    BuildingUsed,
	  CurriculumStatistics,
	  DormitoryOccupancy,
	  StudentStatistics,
    RoomUsed,
    Average,
    Working,
    Vertical
  },
  beforeMount() {
    this.$_axios2.defaults.timeout = 0;
    this.$store.commit("setShowNetError",false);
  },
  mounted() {
    // this.countScale();
    /*window.onresize = e=>{
      this.countScale();
    }*/

    // document.addEventListener("contextmenu",this.disabledMouseClick)
    document.oncontextmenu = this.disabledMouseClick;
    // document.addEventListener('keydown',this.disableF12)
  },
  destroyed() {
    document.oncontextmenu = null;
    document.removeEventListener("keydown",this.disableF12)
    this.$store.commit("setShowNetError",true);
  },
  methods:{
    disabledMouseClick(e){
      if(e.button===2)
        return false;
    },
    disableF12(e){
      if (e.code === "F12" || e.keyCode === 123){
        e.preventDefault();
        return false;
      }
    },
    /**
     * @desc  计算缩放
     */
    countScale(){
      let width = window.innerWidth;
      if (width < 1920){
        this.scale = width / 1920;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/tools";
.data-visualization{
  width: 100%;
  //min-width: 1920rem;
  //min-height: 1080rem;
  height: 100%;
  transform-origin: left top;
  background-color: #020203;
  user-select: none;
  .top{
    min-height: sh(74);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .content{
    background-repeat: no-repeat;
    height: calc(100% - sh(74));
    background-size: 100% 100%;
    padding: sh(10) sw(36) sh(44);
    display: flex;
    justify-content: space-between;
    .left{
      width: sw(480);
      .ec{
        margin-bottom: sh(17);
      }
    }
    .center{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .bottom{
        display: flex;
        width: sw(896);
        justify-content: space-between;
        padding: 0 sw(32);
        .ec{
          width: sw(400);
        }
      }
    }
    .right{
      width: sw(480);
      .ec{
        margin-bottom: sh(17);
      }
    }
  }
}
</style>
