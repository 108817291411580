<template>
  <echarts ref="ec" ></echarts>
</template>

<script>
import Echarts, {sh, sw} from "./Echarts";
export default {
  name: "working",
  data(){
    return {
      options:{

      }
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){

      this.$_axios2.get("/api/visual-kanban/index/teacher",{
        params:{
          school_id:this.$route.query.school_id
        }
      }).then(res=>{
        let status = res.data.status;
        if (status !== 0){
          return this.$parent.noData = 1;
        }else{
          let {subject_teacher} = res.data.data;
          if (!subject_teacher || subject_teacher.length === 0)return this.$parent.noData = 1;
          let comHeight = this.$el.clientHeight;
          let xData = []
          let yData = []
          subject_teacher.forEach(item=>{
            let sbj = item.subject_name+"\n"+ (item.subject_name === "其他"?"科目":"老师");
            xData.push(sbj);
            yData.push(item.total)
          })
          let options = {
            grid:{
              borderWidth:0,
              left:sw(37),
              right:sw(42),
              top:sh(6),
              height:comHeight - sh(6+40)
            },
            xAxis:{
              data:xData,
              axisTick: { show: false },
              splitLine:{
                show:false
              }
            },
            yAxis:{
              show:false,
              max(val){
                return val.max + 10;
              },
              splitLine:{
                show:true,
                lineStyle:{
                  type:[sw(40),sw(60)],
                },
              }

            },
            series:{
              data:yData,
              barWidth:sw(10),
              label:{
                show:true,
                position:"top",
                fontSize:sh(10)
              },
              lineStyle:{
                normal:{
                  type:"dashed",
                  color:"red",
                }
              }
            }
          }
          this.$refs.ec.render(options);
        }
      }).catch(err=>{
        const msg = err.message.toLowerCase();
        if (msg.indexOf("timeout") > -1 || msg.indexOf("network") > -1)
          this.$parent.noData = 2
        else this.$parent.noData = 1;
      })


    }
  },
  components:{
    Echarts
  }
}
</script>

<style scoped>

</style>
