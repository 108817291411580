<template>
  <div class="echarts">

  </div>
</template>

<script>
import * as echarts from "echarts"
export function sh(num){
  let h = window.innerHeight;
  return num * window.innerHeight / 1080;
}
export function sw(num){
  let w = window.innerWidth;
  return num * window.innerWidth / 1920;
}

export default {
  props:{
    options:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    let guid = this.$tools.GUID();
    return {
      ec:null,
      guid
    }
  },
  watch:{
    options(o,n){
      // this.init();
    }
  },
  mounted() {
    // this.init();
    window.addEventListener("resize",this.resize);
  },
  destroyed() {
    window.removeEventListener("resize",this.resize);
  },
  methods:{
    resize(){
      if (this.ec)this.ec.resize();
    },
    render(opt){
      this.init(opt);
    },
    init:function(opt){
      let dataAxis = [];
      let data = [];
      let comHeight = this.$el.clientHeight;
      /**
       * @desc 整合默认值
       * @param {Object} deVal 默认值
       * @param {Array|Object?} val 传入的值
       * @return {Object|Array}
       */
      let deValue = (deVal, val)=>{
        if (!val)return deVal;
        if (Array.isArray(val)){
          return val.map(item=>{
            return Object.assign({},deVal,item);
          })
        }else
          return Object.assign({},deVal,val);
      }
      let series = deValue({
        type: 'bar',
        showBackground: true,
        barWidth:sw(12),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#0EECE4' },
            { offset: 1, color: '#058FE7' }
          ])
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#058FE7' },
              { offset: 1, color: '#0EECE4' }
            ])
          }
        },
        data: data
      },opt.series);
      const xAxis = deValue({
        data: dataAxis,
        nameTextStyle :{
          color:"white",
          fontSize:10
        },
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
        axisLabel:{
          fontSize: sw(10),
        }
      },opt.xAxis);
      const yAxis =  deValue({
        axisTick:{
          show:false
        },
        axisLine: {
          show:false
        },
        axisLabel: {
          show:false,
          width:0
        },
        max:function (val) {
          return val.max + 50;
        }
      },opt.yAxis);
      let option = {
        ...opt,
        backgroundColor:"#061425",
        ...{
          xAxis,
          yAxis,
          series
        }
      }
      let ec = this.ec;
      if (!this.ec){
        ec = this.ec = echarts.init(this.$el,'dark')
        ec.setOption(option);
      }else
        ec.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.echarts{
  width: 100%;
  height: 100%;
}
</style>
