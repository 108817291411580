<template>
  <div id="app">
<!--    <button @click="test">菜单重渲染渲染</button>-->
    <watermark position="absolute" :config="watermark"></watermark>
    <!-- 一级路由出口 -->
    <router-view></router-view>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Watermark from "./components/Watermark";

export default {
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapState(["watermark"]),
    // 水印文字
    watermarkText() {
      let {userinfo, school_arr} = this.userInfo;
      let date = (new Date()).Format("yyyy-MM-dd");
      let school = []
      school_arr = ["达州市鸿鹄高中"]
      for (let k in school_arr) {
        if (school.length === 0)
          school.push(school_arr[k] + "    ");
        else {
          school = [];
          break;
        }
      }
      return `${school[0] || ""}${userinfo.username === "admin" ? "admin" : userinfo.username.slice(-4)}    ${date}`
    },
  },
  mounted() {
    this.getBrowserVersion()
  },
  data() {
    return {
      watermarkInfo: {
        size: 24,
        angle: 30,
        color: "#ccc",
        between: [200, 100]
      },
      text: ""
    }
  },
  components: {
    watermark: Watermark
  },
  methods: {
    ...mapActions(["analyseRouter"]),
    test() {
      this.analyseRouter()
    },
    getBrowserVersion() {
      let userAgent = navigator.userAgent
      let version = null,
          msg = '';

      // if (/MSIE (\d+\.\d+);/.test(userAgent)) { // 匹配IE浏览器
      //   version = parseFloat(RegExp.$1);
      // }
      // if (/Opera\/([\d.]+)/.test(userAgent)) { // 匹配Opera浏览器
      //   version = parseFloat(RegExp.$1);
      // }

      if (/Firefox\/([\d.]+)/.test(userAgent)) { // 匹配Firefox浏览器
        version = parseFloat(RegExp.$1);
        if (version < 114) {
          msg = '您的浏览器版本过低，请升级到最新版本，升级下载地址：http://www.firefox.com.cn/'
          this.$alert(msg, '提示', {
            dangerouslyUseHTMLString: true,
            type: 'warning',
            center: true,
            showConfirmButton: false,
            showClose: false,
          });
        }
      } else if (/Chrome\/([\d.]+)/.test(userAgent)) { // 匹配Chrome浏览器
        version = parseFloat(RegExp.$1);
        if (version < 114) {
          msg = '您的浏览器版本过低，请升级到最新版本，升级下载地址：https://www.google.cn/chrome/index.html'
          this.$alert(msg, '提示', {
            dangerouslyUseHTMLString: true,
            type: 'warning',
            center: true,
            showConfirmButton: false,
            showClose: false,
          });
        }
      } else if (/Version\/([\d.]+).*Safari/.test(userAgent)) { // 匹配Safari浏览器
        version = parseFloat(RegExp.$1);
        if (version < 14) {
          msg = '您的浏览器版本过低，请升级到最新版本'
          this.$alert(msg, '提示', {
            dangerouslyUseHTMLString: true,
            type: 'warning',
            center: true,
            showConfirmButton: false,
            showClose: false,
          });
        }
      }else{
        msg = '系统不兼容此浏览器，请下载火狐，谷歌或Safari（苹果电脑）浏览器使用鸿鹄教育信息化系统\n' +
            '火狐下载地址：http://www.firefox.com.cn/\n' +
            '谷歌下载地址：https://www.google.cn/chrome/index.html'
        this.$alert(msg, '提示', {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          center: true,
          showConfirmButton: false,
          showClose: false,
        });
      }
    }
  }
}
</script>
<style lang="scss">
#app {
  height: 100%;
}
// 更改Placeholder 颜色
$placeholderColor:#888;
input::-ms-input-placeholder{
  color:$placeholderColor !important;
}
input::-webkit-input-placeholder{
  color:$placeholderColor !important;
}
::-moz-placeholder{
  color:$placeholderColor !important;
}
:-moz-placeholder{
  color:$placeholderColor !important;
}
input:-ms-input-placeholder{
  color:$placeholderColor !important;
}
input::placeholder{
  color:$placeholderColor !important;
}


.img-upload-loading, .el-image-viewer__wrapper {
  z-index: 3500 !important;
}
</style>
