import config from "./config.js";
// import config from "./config_backup.js"
import Vue from "vue";
import VueRouter from "vue-router";
import tools from "../utils/tools";
import vuex from "../store/index";
import Element from "element-ui";
import axios1 from "../utils/request_2";
import DataVisualization from "../views/DataVisualization";
//引入页面级别组件  首屏只加载必要的两个页面级别组件 登录&框架
import Login from "@/views/Login/Login.vue";
import Layout from "@/views/Layout/Layout.vue";
import LoginError from "@/views/Error/index.vue";
//阻止原地跳转的错误提示
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
// 对config进行依次正确的修改
tools.DFS(config,(res)=>{
  if (res._tier < 2 && res.removeRedirect !== false){
    delete res.redirect
  }
},{child:"children"})
let routes = [
  {
    path: '/Login',
    name: 'login',
    meta: { title: '登录' },
    component: Login
  },
  {
    path: '/personal/details',
    name: 'PersonalDetails',
    meta: { title: '个人资料' },
    component: () => import('@/views/PersonalSetting/Details.vue')
  },
  {
    path: '/personal/account',
    name: 'PersonaAccount',
    meta: { title: '账号设置' },
    component: () => import('@/views/PersonalSetting/Account.vue')
  },
  {
    path: '/other/cmd',
    name: 'otherCmd',
    meta: { title: 'cmd' },
    component: () => import('@/views/Other/CMD.vue')
  },
  {
    path: '/data-visualization',
    name: 'dataVisualization',
    meta: { title: '数据可视化', mustLogin: false },
    component: DataVisualization
  },
  {
    name: 'error',
    meta: { title: '错误' },
    path: '/error',
    component: LoginError
  },
  {
    path: '/',
    name: 'root',
    component: Layout,
    children: config
  }
]
const router = new VueRouter({
  mode: "history",
  routes
});

function getMenuIndex(route , title){
  for (let i = 0;i<route.length;i++){
    let item = route[i];
    if (item.name === title){
      return i;
    }
  }
  return -1;
}
let first = true;

/**
 * 查询菜单树中是否有该菜单
 * @param menuConfig 菜单树
 * @param name 要查询的菜单名
 * @return {Object}
 */
function getMenu(menuConfig ,name){
  try {
    tools.DFS(menuConfig,(res)=>{
      if (res.name === name)throw res;
    },{child:"child"});
    return null;
  }catch (e) {
    return e;
  }
}

router.beforeEach((to,from,next)=>{
  let matched = to.matched;
  let menuConfig = vuex.getters.menuConfig;
  let len = matched.length;
  let style = to.meta.style || 0;
  let customStyle = to.meta.customStyle || {};
  let breadStyle = to.meta.breadStyle || {};
  let token = vuex.getters.token;
  let mustLogin = to.meta.mustLogin;
  let ignore = to.meta.ignore; // 忽略菜单权限判定(开发中使用)
  let com = matched[len-1].components.default;

  // 优化第一次加载数据 多次请求问题
  if (first && !!com.activated) {
    let ac = com.activated;
    com.activated = function() {
      first = false;
      this.$options.activated[0] = ac;
    }
  }
  try{
    Element.MessageBox.close()
  }catch (e){
  }

  if (!token && to.name!=='login')return next({path:"/login"});
  vuex.commit("setPageStyle",style);
  vuex.commit("setPageCustomStyle",customStyle);
  vuex.commit("setBreadStyle",breadStyle);
  if (mustLogin === false){
    return next();
  }
  if (ignore)return next();

  if(to.name === "root"){
    if (getMenu(menuConfig,"首页")){
      return next({
        path:"/main"
      })
    }
    if (from.name === "home")location.reload();
    next({
      path:"/home"
    })
    return;
  }else if (to.name === "home"){
    if (getMenu(menuConfig,"首页")){
      return next({
        path:"/main"
      })
    }
    next();
    return;
  }
  if (len === 0){
    next({
      path:"/error",
      name:"error",
      query:{type:404}
    });
    return;
  }else if(len === 1){
    next();
    return;
  }
  if (vuex.state.rightMenuIndex === -1){
    let topMenu = matched[1].meta.title;
    let menuIndex = getMenuIndex(menuConfig,topMenu);
    vuex.commit("setRightMenuIndex",menuIndex);
  }
  if (len !== 5){
    let title = to.meta.title;
    let params = {};
    try {
      tools.BFS(menuConfig,item=>{
        if (item.name === title){
          // let first_child = item.child[0].name;
          // let url = item.child[0].web_route;
          let getDefaultUrl = function(data){
            if (data.child && data.child.length>0){
              return getDefaultUrl(data.child[0]) || data.web_route;
            }
            else return data.web_route;
          }
          let url = getDefaultUrl(item);
          if (!url){
            params = {
              path:"/error",
              name:"error",
              query:{type:404}
            }
          }else{
            params = {
              path:url
            };
          }
          next(params);
          throw new Error("跳出DFS")
        }
      },{child:"child"});
      return next();
    }catch(e){
      return;
    }
  }else{
    let title = matched[3].meta.title;
    let item = getMenu(menuConfig,title);
    if (item){
      if (matched[4].path.slice(-4) === 'list') // 所有路由为list的 触发获取按钮权限接口
        vuex.dispatch("getButtonPermissions",item.id).finally(next)
      else next();
    }else {
      next({
        path:"/error",
        name:"error",
        query:{type:1}
      })
    }
    // console.clear();
  }
});
router.afterEach((to,from)=>{
  if (to.name !== "login" && to.name !== "dataVisualization" && to.name !== "error"){
    axios1.get("/api/tool/watermark/index").then(res=>{
      let watermark = vuex.state.watermark;
      if (res.data.status === 0){
        let {school_name,
          name,
          time,
          color,
          opacity,
          on_off,
          font_size,
          portrait,
          username,
          transverse} = res.data.data;
        let text = `${school_name||""}    ${name}    ${username}    ${time}`;
        let result = {
          color,
          size:font_size,
          text,
          between:[transverse,portrait],
          opacity,
          power:on_off === 1,
          fullscreen:to.matched.length === 1
        };
        let flag = false;
        for (let k in result){
          const item = result[k],
              _item = watermark[k];
          if (item !== _item && k !== "image") {
            flag = false;
            break;
          }
        }
        if (!flag){
          // result.image = tools.getWatermark(result);
          vuex.commit("setWatermark",result);
        }

      }
    })
  }else{
    vuex.commit("setWatermark",{power:false})
  }
})
export default router

