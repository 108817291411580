//element自定义验证规则
import Vue from '../main'

class Verify{

    static num = (rule, value, callback) => {
        if(value === '') callback()
        if (!(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(value)) {
            callback(new Error())
        } else {
            callback()
        }
    }

    static toFixed1 = (rule, value, callback) => {
      if(value === '') callback()
      if (!(/^([1-9]\d{0,3}|1000)(\.\d{1})?$/).test(value)) {
        callback(new Error())
      } else {
        callback()
      }
    }

    static sliceIdCard = (str) => {
      let start = str.indexOf('(')
      if(start === -1){
        return str
      }else{
        return str.slice(start + 1,-1)
      }
    }


}

export default Verify